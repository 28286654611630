import styles from './css/style.module.css'
import imgLoading from '../../assets/img/ajax-loader.gif';

function Loading(){

    return (
        <div className={styles.loadingPage}>
            <img src={imgLoading} alt="" title="" />
        </div>

    )

}

export default Loading