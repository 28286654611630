import ScrollTop from '../../components/ScrollTop'
import {Link} from 'react-router-dom'
import { FaGithub, FaLinkedin } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useContext } from 'react'
import { GlobalContext } from '../../contexts/global'
import parse from 'html-react-parser'

import styles from './css/style.module.css'

function Footer(){

    const { configData } = useSelector((rootReducer) => rootReducer.configuration)

    const { translateContent } = useContext(GlobalContext)
    
    if(!configData) {
        return false
    }

    return(
        
        <>

            <footer className={`col-12 p-0 ${styles.footer_main}`}>

                <div className={styles.container}>

                    <div className={`col-12 p-0 py-5 text-center`}>

                        <ul className={`list-unstyled m-0 p-0 d-flex flex-wrap justify-content-center`}>


                            {configData['linkedin'] !== null &&

                                <li className={`col-auto m-0 p-0 px-2`}>

                                    <Link to={configData['linkedin']} target='_blank' className={styles.network}>
                                        <FaLinkedin />
                                    </Link>

                                </li>

                            }
                            
                            {configData['github'] !== null &&

                                <li className={`col-auto m-0 p-0 px-2`}>

                                    <Link to={configData['github']} target='_blank' className={styles.network}>
                                        <FaGithub />
                                    </Link>

                                </li>
                            
                            }

                        </ul>

                    </div>

                </div>

                {configData['email'] &&

                    <div className={`col-12 p-0 py-4 text-center ${styles.contactInfo}`}>
                        { parse(configData['email']) }
                    </div>

                }

                <div className={`col-12 p-0 py-5 ${styles.copyright}`}>
                    { translateContent(configData['text_copyright_ptbr'], configData['text_copyright_en']) }
                </div>                

            </footer>

            <ScrollTop />

        </>

    )

}

export default Footer