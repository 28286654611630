import { BrowserRouter } from "react-router-dom";
import RoutesApp from "./routes";

import GlobalProvider from './contexts/global'

function App() {

    setTimeout(() => {
        console.clear()
    }, 3000);

    return (
        <GlobalProvider>

            <BrowserRouter>

                <RoutesApp />

            </BrowserRouter>

        </GlobalProvider>
    );
}

export default App;
