import i18n from 'i18next'
import detector from "i18next-browser-languagedetector"
import { reactI18nextModule } from "react-i18next"

import enus from './lang/en.json'
import ptbr from './lang/pt.json'

const resources = {
    'en-US': enus,
    'pt-BR': ptbr
}


i18n
.use(detector)
.use(reactI18nextModule) // passes i18n down to react-i18next
.init({
    //lng: 'en-US', // if you're using a language detector, do not define the lng option
    debug: false ,
    fallbackLng: "en-US", // use en if detected lng is not available
    resources,
    interpolation: {
        escapeValue: false
    }
})

export default i18n
