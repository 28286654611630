import Loading from '../../components/Loading'
import { useSelector } from 'react-redux'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import {Link} from 'react-router-dom'
import i18n from '../../locales'

import styles from './css/style.module.css'

function NotFound() {

    const { configData } = useSelector((rootReducer) => rootReducer.configuration)
    
    if(configData === null){
        return <Loading />   
    }

    window.scrollTo(0, 0)

    document.title = 'Anderson Canteiro - 404'

    return (
        <>

            <Header />

            <main className={`col-12 p-0 bg-dark`}>

                <section className={`col-12 px-0 ${styles.section_breadcrumb}`}>

                    <div className={`container`}>

                        <ul className={`col-12 row m-0 p-0 py-2 list-unstyled`}>

                            <li className={`col-auto m-0 p-0 pe-4 py-3 text-capitalize`}>

                                <Link to="/">
                                    Home
                                </Link>

                            </li>

                            <li className={`col-auto m-0 p-0 pe-4 py-3 text-capitalize`}>

                                <Link to={`/${i18n.t('menu.contact')}.html`}>
                                    { i18n.t('menu.contact') }
                                </Link>

                            </li>

                        </ul>

                    </div>

                </section>

                <section className={`col-12 p-0 ${styles.contact}`}>

                    <img src={ require('../../assets/img/banner_404.jpg') } alt="" title="" className={styles.img_cover_absolute} />
        
                    <div className={`col-12 p-0 py-5 ${styles.box}`}>

                        <div className={`container px-5`}>

                            <div className={`col-12 p-0 py-5 d-flex flex-wrap justify-content-lg-end justify-content-center`}>

                                <h1 className={`col-12 m-0 p-0 text-white fw-bold text-center`}>
                                    { i18n.t('404.title') }
                                </h1>

                                <h4 className={`col-12 m-0 p-0 pt-5 text-white text-center`}>
                                    { i18n.t('404.text') }
                                </h4>

                                <div className={`col-12 m-0 p-0 pt-5 text-white text-center`}>

                                    <Link to="/" className={`px-5 py-2 rounded-3 text-uppercase ${styles.btn_orange}`}>
                                        { i18n.t('404.home') }
                                    </Link>

                                </div>

                            </div>

                        </div>

                    </div>

                </section>

            </main>            

            <Footer />
        </>
    )

}

export default NotFound
