import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'

import { store } from './redux/store'
import { Provider } from 'react-redux'
import { fetchConfig } from './redux/configuration/slice';

store.dispatch(fetchConfig())

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
            <App />
        </Provider>
);
