import { configureStore } from "@reduxjs/toolkit"
import rootReducer from "./root-reducer"
import createSagaMiddleware from 'redux-saga'
import rootSaga from "./root-saga"

const sagaMiddleware = createSagaMiddleware()
const middleware = [sagaMiddleware]

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
})

sagaMiddleware.run(rootSaga)