import { useSelector } from 'react-redux'
import { useContext } from 'react'
import { GlobalContext } from '../../contexts/global'
import parse from 'html-react-parser'

import styles from './css/style.module.css'

function Video() {

    const { configData, apiUploads, apiVideo } = useSelector((rootReducer) => rootReducer.configuration)

    const { translateContent } = useContext(GlobalContext)
    
    if(!configData) {
        return false
    }

    return(
        <>
            
            <section className={`col-12 p-0 overflow-hidden d-flex flex-wrap align-items-center ${styles.banner_video}`}>

                <div className={`col-12 p-0 text-center ${styles.box_text}`}>

                    <div className="container">

                        <h1 className="col-12 m-0 p-0 pb-3">
                            { parse(translateContent(configData['banner_text1_ptbr'], configData['banner_text1_en'])) }
                        </h1>

                        <h2 className="col-12 m-0 p-0 pt-4">
                            { parse(translateContent(configData['banner_text2_ptbr'], configData['banner_text2_en'])) }
                        </h2>

                        <h3 className="col-12 m-0 p-0 pt-4">
                            { parse(translateContent(configData['banner_text3_ptbr'], configData['banner_text3_en'])) }
                        </h3>

                    </div>

                </div>

                <div className={`${styles.video}`}>
                    <video autoPlay muted loop src={ apiVideo + translateContent(configData['banner_video_ptbr'], configData['banner_video_en']) } poster={ apiUploads + translateContent(configData['image1'], configData['image2']) }>
                        <source src={ apiVideo + translateContent(configData['banner_video_ptbr'], configData['banner_video_en']) } type="video/mp4" />
                    </video>
                </div>

            </section>

        </>
    )
    
}

export default Video