import { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../contexts/global'
import { useSelector } from 'react-redux'
import axios from 'axios'
import parse from 'html-react-parser'
import i18n from '../../locales'

import styles from './css/style.module.css'

function Activity() {

    const { token, apiBase, apiUploads } = useSelector((rootReducer) => rootReducer.configuration)

    const { activity, setActivity, translateContent } = useContext(GlobalContext)
    const [update, setUpdate] = useState(0)

    useEffect(() => {   
        
        if(activity.length === 0 && update === 0) {

            const route = apiBase + 'activity?token=' + token

            axios.get(route)
            .then((response) => {

                setActivity(response.data['activity'])
                setUpdate(1)

            }).catch((error) => {

                setUpdate(0)
            })
    
        }

    })

    if(activity.length === 0){

        return false
        
    }

    return (
        <>
            <section className={`col-12 p-0 ${styles.section_cards}`}>

                <div className={`col-12 p-0 bg-white`}>

                    <div className={`container pb-5`}>

                        <h2 className={`col-12 m-0 p-0 pb-4 text-uppercase ${styles.title_section}`}>
                            { i18n.t('activity.title') }
                        </h2>

                        <h3 className={`col-12 m-0 p-0 py-4 text-center ${styles.subtitle_section}`}>
                            { i18n.t('activity.subtitle') }
                        </h3>

                    </div>

                </div>

                {activity.map((value, index) => (

                    <div className={`row m-0 ${ (index%2 !== 0) ? styles.bg_2 : styles.bg_1 }`} key={`activity_ ${value.id}`}>

                        <div className={`col-md-6 p-0 ${ (index%2 !== 0) ? 'order-md-1 order-0' : '' } `}>

                            <img src={ apiUploads + translateContent(value['image_ptbr'], value['image_en']) } alt={ parse(translateContent(value['title_ptbr'], value['title_en'])) } title={ parse(translateContent(value['title_ptbr'], value['title_en'])) } className={`${styles.img_cover}`} />

                        </div>

                        <div className={`col-md-6 p-0 p-5`}>

                            <div className={`col-12 p-lg-5 p-md-0 p-2`}>

                                <h2 className={`col-12 m-0 p-0 ${styles.text1}`}>
                                    { parse(translateContent(value['title_ptbr'], value['title_en'])) }
                                </h2>

                                <div className={`col-12 p-0 pt-4 mt-2 ${styles.text2}`}>
                                    { parse(translateContent(value['text_ptbr'], value['text_en'])) }
                                </div>

                            </div>

                        </div>

                    </div>

                ))}                

            </section>

        </>
    )
    
}

export default Activity