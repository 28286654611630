import { FaChevronUp, FaUserAstronaut } from 'react-icons/fa'

import styles from './css/style.module.css'

function ScrollTop() {

    window.addEventListener("scroll", (event) => {

        let classRocket = '.' + styles.rocket
        let classShow = styles.show
        //let classActive = styles.active

        let scrollTop = window.scrollY;

        let rocket = document.querySelector(classRocket)

        if(!rocket.classList.contains(classShow) && scrollTop > window.innerHeight){

            //rocket.classList.remove(classActive)

            rocket.classList.add(classShow)

        }else if(rocket.classList.contains(classShow) && scrollTop < window.innerHeight){

            rocket.classList.remove(classShow)

        }        

    })

    function activeRocket() {

        window.scrollTo(0, 0)

        let classActive = styles.active

        /*const element = document.querySelector('body')
        element.scrollIntoView({ behavior: 'smooth' })*/

        let classRocket = '.' + styles.rocket
        let rocket = document.querySelector(classRocket)

        if(!rocket.classList.contains(classActive) ){
            
            rocket.classList.add(classActive)

            setTimeout(()=>{
                rocket.classList.remove(classActive)
            }, 1500)

        }/*else if(rocket.classList.contains('active')){

            rocket.classList.remove("active")

        }*/

    }    

    return(
        <>
            <button className={`${styles.rocket}`} onClick={() => {
                activeRocket();
            }}>

                <div className={`${styles.boxArrow}`}>
                    <FaChevronUp />
                </div>

                <div className="box">
                    <FaUserAstronaut />
                </div>
                <div className={`${styles.wingLeft}`}></div>
                <div className={`${styles.wingRight}`}></div>
                <div className={`${styles.base}`}></div>
                <div className={`${styles.fire}`}>

                    {[...Array(50)].map((x, i) =>
                       <div className={`${styles.particle}`} key={i}></div>
                    )}

                </div>
            </button>

        </>
    )
    
}

export default ScrollTop