import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    //apiBase: 'http://localhost/ascanteiro/laravel/api/',
    apiBase: 'https://cybertron.ascanteiro.com/api/',
    token: '2075ASCANTEITO00011011',
    configData: null,
    apiVideo: 'https://cybertron.ascanteiro.com/storage/app/video/',
    apiUploads: 'https://cybertron.ascanteiro.com/storage/app/uploads/',
    apiUploadsThumb: 'https://cybertron.ascanteiro.com/storage/app/uploads/thumb/'
}

export const configurationSlice = createSlice({
    name: "configuration",
    initialState: initialState,
    reducers: {
        fetchConfig: (state, action) => {

        },
        fetchConfigSuccess: (state, action) => {

            state.configData = action.payload.configuration[0]

        },
        fetchConfigFailure: (state, action) => {

        }        
    }
})

export const {
    fetchConfig,
    fetchConfigSuccess,
    fetchConfigFailure
} = configurationSlice.actions

export default configurationSlice.reducer