import Loading from '../../components/Loading'
//import MetaTags from './MetaTags'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Video from '../../components/Video'
import About from '../../components/About'
import Technology from '../../components/Technology'
import Activity from '../../components/Activity'
import { useSelector } from 'react-redux'

function Home(){

    const { configData } = useSelector((rootReducer) => rootReducer.configuration)
    
    if(configData === null){
        return <Loading />   
    }

    window.scrollTo(0, 0)

    document.title = 'Anderson Canteiro - Home'

    return(
        <>            
            <Header />

            <Video />

            <About />

            <Technology />

            <Activity />

            <Footer />

        </>
    )

}

export default Home