import { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../contexts/global'
import { useSelector } from 'react-redux'
import axios from 'axios'
import parse from 'html-react-parser'
import i18n from '../../locales'

import styles from './css/style.module.css'

function Technology(){

    const { token, apiBase, apiUploads } = useSelector((rootReducer) => rootReducer.configuration)

    const { technology, setTechnology, translateContent } = useContext(GlobalContext)
    const [update, setUpdate] = useState(0)

    useEffect(() => {   
        
        if(technology.length === 0 && update === 0) {

            const route = apiBase + 'technology?token=' + token

            axios.get(route)
            .then((response) => {

                setTechnology(response.data['technology'])
                setUpdate(1)

            }).catch((error) => {

                setUpdate(0)
            })
    
        }

    })

    if(technology.length === 0){

        return false
        
    }

    return (
        <>

            {technology.length &&

                <section className={`col-12 p-0 py-5 bg-white ${styles.technology}`} id="technology">

                    <div className="container py-5">

                        <h2 className={`col-12 m-0 p-0 pb-4 text-uppercase ${styles.title_section}`}>
                            { i18n.t('technology.title') }
                        </h2>

                        <div className={`col-12 p-0 py-5`}>

                            <div className={`row m-0 jusity-content-center`}>
                             
                                {technology.map((value) => (

                                    <div className={`col-lg-4 col-sm-6 px-3 py-5`} key={`technology_ ${value.id}`}>

                                        <div className={`col-12 p-0 text-center ${styles.item}`}>

                                            <div className={`col-12 p-0 ${styles.box_img}`}>

                                                <img src={ apiUploads + translateContent(value['image_ptbr'], value['image_en']) } alt={ parse(translateContent(value['title_ptbr'], value['title_en'])) } title={ parse(translateContent(value['title_ptbr'], value['title_en'])) } className={`img-fluid`} />

                                            </div>

                                            <div className={`col-12 p-0 py-3 ${styles.text1}`}>
                                                { parse(translateContent(value['title_ptbr'], value['title_en'])) }
                                            </div>

                                            <div className={`col-12 p-0 pb-5 ${styles.text2}`}>
                                                { parse(translateContent(value['text_ptbr'], value['text_en'])) }
                                            </div>

                                        </div>

                                    </div>

                                ))}
                            

                            </div>

                        </div>

                    </div>

                </section>

            }

        </>
    )

}

export default Technology