import Loading from '../../components/Loading'
//import MetaTags from './MetaTags'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import i18n from '../../locales'
import { useForm } from "react-hook-form"
import Swal from 'sweetalert2'
import { useState, useEffect } from "react"
import axios from 'axios'
import { useSelector } from 'react-redux'
import {Link} from 'react-router-dom'

import styles from './css/style.module.css'
import imgContact from '../../assets/img/banner_contact.jpg'
import imgLoading from '../../assets/img/ajax-loader.gif';

function Contact(){

    const [ip, setIP] = useState("")

    const { apiBase, token, configData } = useSelector((rootReducer) => rootReducer.configuration)

    useEffect(() => {

        setTimeout(()=>{

            fetch("https://api.ipify.org/?format=json")
            .then((resp) => resp.json())
            .then((data) => {
                setIP(data.ip)
            })

        }, 3000)

    }, [])

    const {register, handleSubmit} = useForm()

    function handleFormError(msg) {

        Swal.fire({
            title: i18n.t('error.error'),
            text: msg,
            icon: 'error',
            confirmButtonText: 'ok'
        })
        
    }

    function submitContact(data){

        //Swal.showLoading()

        Swal.fire({
            imageUrl: `${imgLoading}`,
            imageHeight: 150,
            imageAlt: "...",
            text: i18n.t('contact.sending'),
            showCancelButton: false,
            showConfirmButton: false,
        });
        
        if( !data.name ){
            handleFormError(i18n.t('error.emptyName'))
        }else if( !data.email ){
            handleFormError(i18n.t('error.emptyEmail'))
        }else if( !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email) ) {
            handleFormError(i18n.t('error.invalidEmail'))
        }else if( !data.subject ){
            handleFormError(i18n.t('error.emptySubject'))
        }else if( !data.message ){
            handleFormError(i18n.t('error.emptyText'))
        }else{

            ////maybe it could be a problem with headers
            //headers: {
                //'Accept': 'application/json, text/plain, */*',
                //'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8',
            //},

            let route = apiBase + 'contact/submit' 

            axios
            .post(route, {
                token: token,
                ip: ip,
                name: data.name,
                email: data.email,
                subject: data.subject,
                text: data.message
            })
            .then(response => {
                
                document.getElementById('formContact').reset()
 
                Swal.fire({
                    title: i18n.t('success.success'),
                    text: i18n.t('success.sentSuccess'),
                    icon: 'success',
                    confirmButtonText: 'ok'
                })

            })
            .catch(error => {
                 
                Swal.fire({
                    title: i18n.t('error.error'),
                    text: i18n.t('error.unknownError'),
                    icon: 'error',
                    confirmButtonText: 'ok'
                })

            })

        }
        
    }

    if(configData === null){
        return <Loading />   
    }

    window.scrollTo(0, 0)

    document.title = 'Anderson Canteiro - ' + i18n.t('contact.title') 

    return(
        <>
            <Header />
        
            <main className="col-12 p-0">

                <section className={`col-12 px-0 ${styles.section_breadcrumb}`}>

                    <div className="container">

                        <ul className="col-12 row m-0 p-0 py-2 list-unstyled">

                            <li className="col-auto m-0 p-0 pe-4 py-3 text-capitalize">

                                <Link to="/">
                                    Home
                                </Link>

                            </li>

                            <li className="col-auto m-0 p-0 pe-4 py-3 text-capitalize">

                                <Link to={`/${i18n.t('menu.contact')}.html`}>
                                    { i18n.t('menu.contact') }
                                </Link>

                            </li>

                        </ul>

                    </div>

                </section>

                <section className={`col-12 p-0 ${styles.contact}`}>

                    <img src={imgContact} alt="" title="" className={styles.img_cover_absolute} />

                    <div className={`col-12 p-0 py-5 `}>

                        <div className="container px-5">

                            <div className="col-12 p-0 py-5 d-flex flex-wrap justify-content-lg-end justify-content-center">

                                <div className={`col-lg-6 col-md-8 p-sm-5 p-4 py-5 text-start bg-white ${styles.box}`}>

                                    <form className="col-12 p-0" id="formContact" onSubmit={handleSubmit(submitContact)}>

                                        <div className="col-12 px-4">

                                            <h1 className={`col-12 m-0 p-0 pb-3 text-uppercase ${styles.title}`}>
                                                { i18n.t('contact.title') }
                                            </h1>

                                            <div className={`col-12 p-0 py-4 ${styles.subtitle}`}>
                                                { i18n.t('contact.subtitle') }
                                            </div>

                                            <label className="col-12 p-0 py-2">
                                                <input type="text" className={`form-control ${styles.formControl}`} placeholder={ i18n.t('contact.name') } {...register("name")} id="name" />                                
                                            </label>

                                            <label className="col-12 p-0 py-2">
                                                <input type="text" className={`form-control ${styles.formControl}`} placeholder={ i18n.t('contact.email') } {...register("email")} id="email" />
                                            </label>

                                            <label className="col-12 p-0 py-2">
                                                <input type="text" className={`form-control ${styles.formControl}`} placeholder={ i18n.t('contact.subject') } {...register("subject")} id="subject" />
                                            </label>

                                            <label className="col-12 p-0 py-2">
                                                <textarea name="text" className={`form-control ${styles.formControl}`} placeholder={ i18n.t('contact.message') } {...register("message")} id="message"></textarea>
                                            </label>

                                            <label className="col-12 p-0 py-2">
                                                <button type="submit" className={`px-5 py-2 rounded-3 border-0 w-100 text-uppercase ${styles.btnSend}`}>
                                                    { i18n.t('contact.send') }
                                                </button>
                                            </label>

                                        </div>

                                    </form>

                                </div>

                            </div>

                        </div>

                    </div>

                </section>

            </main>

            <Footer />

        </>
    )

}

export default Contact