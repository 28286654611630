import { Routes, Route } from "react-router-dom"

import Home from "../pages/Home"
import Contact from "../pages/Contact"
import NotFound from '../pages/NotFound';

function RoutesApp() {

    return(
        
        <Routes>

            <Route path="/" element={ <Home /> } />

            {/*Portuguese routes*/}
            <Route path="/contato.html" element={ <Contact /> } />

            {/*English routes*/}
            <Route path="/contact.html" element={ <Contact /> } />

            <Route path="*" element={<NotFound />}></Route>

        </Routes>

    )
    
}

export default RoutesApp