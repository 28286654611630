import axios from 'axios'
import { select, all, takeEvery, call, put } from 'redux-saga/effects'
import {fetchConfigSuccess, fetchConfigFailure} from './slice'

function* fetchConfig(){

    const state = yield select()

    const apiBase = state.configuration.apiBase
    const token = state.configuration.token

    try {

        const route = apiBase + 'configuration?token=' + token

        const response = yield call(axios.get, route)

        yield put(fetchConfigSuccess(response.data))
        
    } catch (error) {
        
        yield put(fetchConfigFailure(error.message))

    }

}

export default all([
    takeEvery("configuration/fetchConfig", fetchConfig)
])