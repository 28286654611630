import { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../contexts/global'
import { useSelector } from 'react-redux'
import axios from 'axios'
import parse from 'html-react-parser'
import i18n from '../../locales'

import styles from './css/style.module.css'

function About() {

    const { token, apiBase, apiUploads } = useSelector((rootReducer) => rootReducer.configuration)

    const { about, setAbout, translateContent, scrollTo } = useContext(GlobalContext)
    const [update, setUpdate] = useState(0)

    useEffect(() => {   
        
        if(about.length === 0 && update === 0) {

            const route = apiBase + 'home/about?token=' + token

            axios.get(route)
            .then((response) => {

                setAbout(response.data['about'])
                setUpdate(1)

            }).catch((error) => {

                setUpdate(0)
            })
    
        }

    })

    if(about.length === 0){

        return false
        
    }

    return (
        <>
            {about.map((value) => (
            
                <section className={`col-12 p-0 py-5 ${styles.aboutHome}`} key={`about_${value.id}`}>

                    <div className={`py-5 ${styles.container}`}>

                        <h3 className={`col-12 m-0 p-0 pb-4 text-center text-uppercase ${styles.title_section}`}>
                            { parse(translateContent(value['title_ptbr'], value['title_en'])) }
                        </h3>

                        <div className={`col-12 p-0 pt-5 mt-5`}>

                            <div className={`row m-0 justify-content-center`}>

                                <div className={`col-md-5 col-sm-8 p-0 pb-lg-0 pb-4`}>

                                    <div className={`col-12 px-5`}>

                                        <div className={`col-12 p-0 ${styles.boxImg}`}>

                                            <div className={`${styles.top}`}></div>

                                            <div className={`${styles.bottom}`}></div>

                                            <img src={ apiUploads + translateContent(value['image_ptbr'], value['image_en']) } alt={ parse(translateContent(value['title_ptbr'], value['title_en'])) } title={ parse(translateContent(value['title_ptbr'], value['title_en'])) } className={`w-100`} />

                                        </div>

                                    </div>

                                </div>

                                <div className={`col-md-7 px-md-4 p-0 py-4`}>

                                    <h3 className={`col-12 m-0 p-0 ${styles.title}`}>
                                        { parse(translateContent(value['lead_ptbr'], value['lead_en'])) }
                                    </h3>

                                    <div className={`col-12 p-0 pt-5 ${styles.text}`}>
                                        { parse(translateContent(value['text_ptbr'], value['text_en'])) }
                                    </div>

                                    <div className={`col-12 p-0 pt-5`}>

                                        <button className={`px-4 py-2 border-0 rounded-3 ${styles.btn_orange}`} onClick={ () => scrollTo('technology') }>
                                            { i18n.t('common.more') }
                                        </button>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </section>

            ))}

        </>
    )
    
}

export default About