import { useState, createContext } from "react"

export const GlobalContext = createContext({})

function GlobalProvider({children}) {

    const [seoHome, setSeoHome] = useState([])
    const [seoContact, setSeoContact] = useState([])
    const [about, setAbout] = useState([]);
    const [technology, setTechnology] = useState([]);
    const [activity, setActivity] = useState([]);

    const I18N_STORAGE_KEY = 'i18nextLng'
    let language = localStorage.getItem(I18N_STORAGE_KEY)

    function translateContent (pt_br, en_us){
        
        if(language === 'en-US'){

            return en_us
    
        }else if(language === 'pt-BR'){
    
            return pt_br
    
        }

    }

    function scrollTo(id){

        if(id){

            const element = document.getElementById(id)
    
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
    
        }else{
    
            const element = document.querySelector('body')
    
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
    
        }

    }

    return (
        <GlobalContext.Provider value={
                {
                    translateContent, 
                    scrollTo,
                    seoHome, 
                    setSeoHome,
                    seoContact, 
                    setSeoContact,
                    about, 
                    setAbout,
                    technology, 
                    setTechnology,
                    activity, 
                    setActivity
                }
            }>
            {children}
        </GlobalContext.Provider>
    )
    
}

export default GlobalProvider