import {Link} from 'react-router-dom'
import { FaGithub, FaLinkedin } from 'react-icons/fa'
import {useEffect, useRef } from 'react'
import i18n from '../../locales'
import { useSelector } from 'react-redux'

import styles from './css/style.module.css'
import imgBrazil from '../../assets/img/brazil.png'
import imgUsa from '../../assets/img/usa.png'

function Header(){

    const { configData } = useSelector((rootReducer) => rootReducer.configuration)

    const refBtnMenu = useRef(null)

    const menuStatus = useRef(0)

    useEffect(() => {

        document.addEventListener("click", ((e)=>{

            const menuMobile = document.querySelector('#menu_mobile');
            
            if (refBtnMenu.current && !refBtnMenu.current.contains(e.target) && menuStatus.current === 1){
               
                menuMobile.classList.remove(`${styles.show}`)
                menuStatus.current = 0
                
            }

        }), true)

        
    })
    

    const I18N_STORAGE_KEY = 'i18nextLng'

    let language = localStorage.getItem(I18N_STORAGE_KEY)

    function setLanguage(locale){

        localStorage.setItem(
            I18N_STORAGE_KEY,
            locale
        )

        window.location.reload(false)

    }

    function menu() {

        const classShow = `${styles.show}`
        const menuMobile = document.querySelector('#menu_mobile');

        if(menuStatus.current === 0){

            menuMobile.classList.add(classShow)

            menuStatus.current = 1

        }else{

            menuMobile.classList.remove(classShow)

            menuStatus.current = 0
        }
        
    }

    function header_fixed(){

        let classHeader = '.' + styles.header_main
        let classActive = styles.active

        let scrollTop = window.scrollY;

        let header_main = document.querySelector(classHeader)

        if(!header_main.classList.contains(classActive) && scrollTop > 300){

            header_main.classList.add(classActive)

        }else if(header_main.classList.contains(classActive) && scrollTop < 300){

            header_main.classList.remove(classActive)

        }

    }

    window.addEventListener("scroll", (event) => {

        header_fixed()

    });

    if(!configData) {
        //here a should create a loading
        return false
    }

    return(
        <>     
            
            <section className={`row m-0 px-3 ${styles.transition} ${styles.menu_mobile}`} id="menu_mobile">

                <div className={`p-0 ${styles.top}`}>

                    <div className={`row m-0`}>

                        <div className={`col pe-0 ps-3 d-flex align-items-center`}>
                            <div>
                                <img src={require('../../assets/img/logo.png')} alt="" title="" className={styles.logo} />
                            </div>
                        </div>

                        <div className={`col-auto p-0`}>

                            <button type="button" className={styles.btn_menu} onClick={()=>menu()} ref={refBtnMenu}>
                                <div className={styles.line}></div>
                                <div className={styles.line}></div>
                                <div className={styles.line}></div>
                            </button>

                        </div>

                    </div>

                </div>

                <div className={`col-12 align-self-stretch p-0 ${styles.box_content} ${styles.perfect_scrollbar}`}>

                    <ul className={`list-unstyled col-12 p-0 m-0`}>

                        <li className={`col-12 p-0 m-0 text-capitalize`}>

                            <Link to="/" className={styles.mother}>
                                Home
                            </Link>

                        </li>

                        <li className={`col-12 p-0 m-0 text-capitalize`}>

                            <Link to={`/${i18n.t('menu.contact')}.html`} className={styles.mother}>
                                { i18n.t('menu.contact') }
                            </Link>

                        </li>

                        <li className={`col-12 p-0 py-5 m-0 text-center`}>

                            {configData['linkedin'] !== null &&

                                <Link to={configData['linkedin']} target='_blank' className={`m-2 ${styles.network}`}>
                                    <FaLinkedin />
                                </Link>

                            }
                            
                            {configData['github'] !== null &&

                                <Link to={configData['github']} target='_blank' className={`m-2 ${styles.network}`}>
                                    <FaGithub />
                                </Link>
                        
                            }

                        </li>

                    </ul>

                </div>

            </section>

            <header className={`d-flex flex-wrap align-items-center transition  ${styles.header_main}`}>
                
                <div className={styles.container}>

                    <div className={`row m-0`}>

                        <div className={`col-auto p-0`}>

                            <Link to="/">
                                <img src={require('../../assets/img/logo.png')} alt="" title="" className={styles.logo} />
                            </Link>

                        </div>

                        <div className={`col d-flex flex-wrap p-0 align-items-center justify-content-end`}>

                            <ul className={`list-unstyled m-0 p-0 d-lg-flex d-none flex-wrap ${styles.menu}`}>

                                <li className={`col-auto m-0 p-0 ps-3 text-capitalize`}>

                                    <Link to="/" className={styles.mother}>
                                        Home
                                    </Link>

                                </li>

                                <li className={`col-auto m-0 p-0 ps-3 text-capitalize`}>

                                    <Link to={`/${i18n.t('menu.contact')}.html`} className={styles.mother}>
                                        { i18n.t('menu.contact') }
                                    </Link>

                                </li>

                            </ul>

                            <ul className={`list-unstyled m-0 p-0 ps-3 d-flex flex-wrap`}>

                                {configData['linkedin'] !== null &&

                                    <li className={`col-auto m-0 p-0 ps-2`}>

                                        <Link to={configData['linkedin']} target='_blank' className={styles.network}>
                                            <FaLinkedin />
                                        </Link>

                                    </li>

                                }
                                
                                {configData['github'] !== null &&

                                    <li className={`col-auto m-0 p-0 ps-2`}>

                                        <Link to={configData['github']} target='_blank' className={styles.network}>
                                            <FaGithub />
                                        </Link>

                                    </li>
                                
                                }

                                <li className={`col-auto m-0 p-0 ps-2`}>

                                    <div className={styles.dropdown}>

                                        <button className="btn p-0 border-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src={ language === 'en-US' ? imgUsa : imgBrazil} alt="Brasil" title="Brasil" className={`rounded-1`} />
                                        </button>

                                        <ul className={`rounded-0 border-0 p-0 m-0 dropdown-menu ${styles.dropdown_menu}`} aria-labelledby="dropdownMenuButton1">
                                            <li className={`col-12 p-0 m-0 text-end`} onClick={()=>setLanguage(language !== 'en-US' ? 'en-US' : 'pt-BR')}>
                                                <img src={language !== 'en-US' ? imgUsa : imgBrazil} alt="Brasil" title="Brasil" className={`rounded-1`} />
                                            </li>
                                        </ul>
                                    </div>

                                </li>

                                <div className={`col-auto p-0 ps-2 d-lg-none`}>

                                    <button type="button" className={styles.btn_menu} onClick={()=>menu()}>
                                        <div className={styles.line}></div>
                                        <div className={styles.line}></div>
                                        <div className={styles.line}></div>
                                    </button>

                                </div>

                            </ul>

                        </div>

                    </div>

                </div>

            </header>

        </>
    )

}

export default Header